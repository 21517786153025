import { IconLookup } from '@fortawesome/fontawesome-svg-core';
import { faChevronRight } from '@fortawesome/pro-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useAppInsightsContext } from '@microsoft/applicationinsights-react-js';
import {
  generateUtilityClasses,
  Stack,
  styled,
  Typography,
} from '@mui/material';
import Link from 'next/link';

const classes = generateUtilityClasses('HeroLink', ['stack']);

const StyledLink = styled(Link)(({ theme }) => ({
  backdropFilter: 'blur(3px)',
  backgroundColor: '#20202033',
  borderRadius: theme.spacing(0.5),
  color: 'inherit',
  cursor: 'pointer',
  display: 'inline-block',
  padding: theme.spacing(2, 2),
  transition: 'all 0.1s ease-in-out',
  textDecoration: 'none',
  '& .hero-arrow-icon': {
    transition: 'all 0.1s ease-in-out',
  },
  '&:hover': {
    backgroundColor: '#FFFFFF33',
    '& .hero-arrow-icon': {
      transform: 'translateX(5px)',
    },
  },
  [`& .${classes.stack}`]: {
    alignItems: 'center',
    justifyContent: 'space-between',
  },
}));

export type HeroLinkProps = {
  icon_type: string;
  icon: string;
  label: string;
  url: string;
};

export function HeroLink(props: HeroLinkProps) {
  const { icon, icon_type, label, url } = props;
  const insights = useAppInsightsContext();

  const handleOnClick = () => {
    insights?.trackEvent({
      name: 'Opened Subpages',
      properties: {
        subpage: label,
      },
    });
  };

  return (
    <StyledLink href={url} onClick={handleOnClick}>
      <Stack className={classes.stack} direction="row">
        <Stack className={classes.stack} direction="row" spacing={2}>
          <FontAwesomeIcon
            icon={{ prefix: icon_type, iconName: icon } as IconLookup}
          />
          <Typography variant="button1">{label}</Typography>
        </Stack>
        <FontAwesomeIcon icon={faChevronRight} />
      </Stack>
    </StyledLink>
  );
}
