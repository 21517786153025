import { Box } from '@mui/material';
import { classes, Root } from './styles';

export const DarkenedComponent = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  return (
    <Root>
      {children}
      <Box className={classes.overlay} />
    </Root>
  );
};
