import { TitleDivider } from '@grimme/components';
import { Box, Container, Grid, Stack, Typography } from '@mui/material';
import { useRef } from 'react';
import { HeroLink } from './hero-link';
import { classes, RootBox } from './styles';
import { VideoComponent } from './video-component';
import { ButterImage } from '../butter-image/butter-image';
import { DarkenedComponent } from '../darkened-component/darkened-component';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronDown } from '@fortawesome/pro-solid-svg-icons';

export type HeroProps = {
  title: string;
  subtitle: string;
  background_image: string;
  background_video_hq: string;
  background_video_lq: string;
  links_overline: string;
  links: {
    icon: string;
    icon_type: string;
    label: string;
    url: string;
  }[];
  tags: {
    label: string;
    url: string;
  }[];
  scroll_down_info_text: string;
};

export function Hero(props: HeroProps) {
  const {
    title,
    subtitle,
    background_image,
    background_video_hq,
    links_overline,
    links,
    scroll_down_info_text,
  } = props;
  const containerRef = useRef<HTMLDivElement>(null);

  const handleScrollToDiscover = () => {
    window.scrollTo({
      top: containerRef.current?.offsetHeight,
      behavior: 'smooth',
    });
  };

  return (
    <RootBox ref={containerRef}>
      <DarkenedComponent>
        <ButterImage
          className={classes.butterImage}
          alt=""
          layout="fill"
          priority
          src={background_image}
        />
        <VideoComponent url={background_video_hq} />
      </DarkenedComponent>
      <Box className={classes.mainBox} component={Container}>
        <Grid
          className={classes.mainGrid}
          columns={{ xs: 4, sm: 8, md: 12 }}
          spacing={{ xs: '12px', sm: '16px', lg: '24px' }}
          container
        >
          <Grid item xs={4} sm={8} md={6}>
            <h1>{title}</h1>
            <h2 className={`${classes.subtitle} g-typography-body1`}>
              {subtitle}
            </h2>
            <TitleDivider />
          </Grid>
          <Grid className={classes.gridItem} item xs={0} md={2} />
          <Grid item xs={4} sm={8} md={4}>
            <Stack className={classes.stack} spacing={2} direction="column">
              <Typography variant="overline">{links_overline}</Typography>
              {links.map((link, i) => (
                <HeroLink key={i} {...link} />
              ))}
            </Stack>
          </Grid>
        </Grid>
      </Box>
      <Stack className={classes.discoverMoreStack}>
        <Box
          onClick={handleScrollToDiscover}
          className={classes.discoverMoreBoxButton}
        >
          <Typography variant="body2">{scroll_down_info_text}</Typography>
          <FontAwesomeIcon icon={faChevronDown} />
        </Box>
      </Stack>
    </RootBox>
  );
}
