import { useProfile } from '@/hooks';
import { useFeatureFlag } from '@/hooks/feature-flags';
import { useFontAwesomeIconsEffect } from '@/hooks/useFontAwesomeIconsEffect/useFontAwesomeIconsEffect';
import { FEATURE_FLAGS } from '@/lib/azure-app-configuration';
import { LandingPageData, PartnersPageData } from '@/lib/butter-types';
import { getContent } from '@/lib/butter/getContent/getContent';
import { getPage } from '@/lib/butter/getPage/getPage';
import { listBlogPosts } from '@/lib/butter/listBlogPosts/listBlogPosts';
import { withDefaultStaticProps } from '@/lib/composable';
import { getProducts } from '@/lib/products';
import { ContactSection } from '@/ui/contact-section/contact-section';
import { DigitalSection } from '@/ui/digital-section/digital-section';
import { GrimmeDataPerRegionProvider } from '@/ui/grimme-data-per-region/provider';
import { Hero } from '@/ui/hero';
import { HeroV2 } from '@/ui/hero-v2';
import { Layout } from '@/ui/layout/layout';
import { NewsSection } from '@/ui/news-section/news-section';
import { PartnersSection } from '@/ui/partners-section/partners-section';
import { ProductDetailsPreview } from '@/ui/product-details-preview/product-details-preview';
import {
  DEFAULT_COUNTRY_CODE,
  DEFAULT_LOCALE,
  NEWS_CATEGORY,
  parseCountriesFromContinents,
  parseCtaSectionButtons,
} from '@/utils';
import { IconLookup } from '@fortawesome/fontawesome-svg-core';
import { GetStaticPropsContext, InferGetStaticPropsType } from 'next';
import { CtasSection, SpacedSection } from '@grimme/components';
import { Container } from '@mui/material';
import { NextSeo } from 'next-seo';
import Separator from '@grimme/ui-components/separator';

export const getStaticProps = async ({
  locale,
  preview = false,
}: GetStaticPropsContext) => {
  return await withDefaultStaticProps({
    revalidate: 300,
    revalidateOnError: 10,
    locale: locale || DEFAULT_LOCALE,
    preview: preview || false,
    props: async ({ includeIcons }) => {
      const landingPageData = await getPage<LandingPageData>(
        locale || DEFAULT_LOCALE,
        preview,
        'grimme-landing-page',
      );

      const heroComponentIcons = landingPageData?.fields?.hero?.links?.map(
        (icon) => ({
          prefix: icon.icon_type,
          iconName: icon.icon,
        }),
      ) as IconLookup[];
      const heroV2ComponentIcons = (
        landingPageData?.fields?.hero_v2?.sections || []
      ).map((section) => ({
        prefix: section.label_icon_type,
        iconName: section.label_icon,
      })) as IconLookup[];
      includeIcons([...heroComponentIcons, ...heroV2ComponentIcons]);

      const heroV2NewsSection =
        landingPageData?.fields?.hero_v2?.sections?.find(
          (section) => section.section_type === 'news',
        );

      if (heroV2NewsSection) {
        const generalBlogPosts = await listBlogPosts({
          locale,
          preview,
          pageNumber: 1,
          pageSize: 1,
        });
        const featuredBlogPosts = generalBlogPosts.data[0]; // Picking latest blog post

        if (featuredBlogPosts) {
          heroV2NewsSection.image = featuredBlogPosts.featured_image!;
          heroV2NewsSection.title = featuredBlogPosts.title;
          heroV2NewsSection.subtitle = featuredBlogPosts.summary;
          heroV2NewsSection.main_button_link = `news/${featuredBlogPosts.slug}`;
        }
      }

      return {
        preview,
        landingPageData,
        newsFeed: await listBlogPosts({
          locale,
          preview,
          pageNumber: 1,
          pageSize: 3,
          categorySlug: NEWS_CATEGORY,
        }),
        productsDetails: await Promise.all(
          landingPageData.fields.product_details_page_preview.categories.map(
            async (category) => ({
              products: await getProducts(
                locale || DEFAULT_LOCALE,
                category.slugs.split(','),
              ),
              category: category.name,
            }),
          ),
        ),
        partnersData: await getPage<PartnersPageData>(
          locale || DEFAULT_LOCALE,
          preview,
          'partners',
        ),
        /*
        IMPORTANT: Calling regions based contacts ways only from DE locale
        because it will be our single source of truth for this kind of data.
        */
        contactsPerRegionData: await getContent(
          DEFAULT_COUNTRY_CODE.toLowerCase(),
          false,
          ['grimme_contacts_per_region'],
        ),
      };
    },
  });
};

export default function IndexPage(
  props: InferGetStaticPropsType<typeof getStaticProps>,
) {
  const {
    contactsPerRegionData,
    globalSettings,
    icons,
    landingPageData,
    newsFeed,
    partnersData,
    preview,
    productsDetails,
  } = props;
  useFontAwesomeIconsEffect(icons);
  const { data: profile } = useProfile();
  const { enabled: isHeroV2Enabled } = useFeatureFlag(FEATURE_FLAGS.HERO_V2, {
    id: profile?.id,
  });

  return (
    <GrimmeDataPerRegionProvider value={{ contactsRaw: contactsPerRegionData }}>
      <Layout globalSettings={globalSettings} preview={preview}>
        <NextSeo
          description={landingPageData.fields.seo.description}
          nofollow={landingPageData.fields.seo.no_index}
          noindex={landingPageData.fields.seo.no_index}
          title={landingPageData.fields.seo.title}
        />
        {isHeroV2Enabled ? (
          <HeroV2 {...landingPageData.fields.hero_v2} />
        ) : (
          <Hero {...landingPageData.fields.hero} />
        )}

        <SpacedSection px="small">
          <Container className="text-center my-20">
            <h2 className="mb-2">
              {landingPageData.fields.seo_section_components_library.title}
            </h2>
            <p className="mb-4">
              {landingPageData.fields.seo_section_components_library.subtitle}
            </p>
            <Separator className="max-w-10 mx-auto" thickness="thicker" />
          </Container>
        </SpacedSection>

        <SpacedSection px="small">
          <ProductDetailsPreview
            productsDetails={productsDetails}
            {...landingPageData.fields.product_details_page_preview}
          />
        </SpacedSection>

        <SpacedSection px="large" bgcolor="white">
          <DigitalSection {...landingPageData.fields.grimme_digital} />
        </SpacedSection>

        <CtasSection
          title={landingPageData.fields.ctas_section.title}
          description={landingPageData.fields.ctas_section.description}
          backgroundImageUrl={
            landingPageData.fields.ctas_section.background_image
          }
          buttons={parseCtaSectionButtons(
            (landingPageData.fields.ctas_section &&
              landingPageData.fields.ctas_section.buttons) ||
              [],
          )}
        />

        <SpacedSection px="large">
          <NewsSection {...landingPageData.fields.news} feed={newsFeed.data} />
        </SpacedSection>

        <SpacedSection px="large">
          <PartnersSection
            {...landingPageData.fields.grimme_partners}
            countriesList={parseCountriesFromContinents(
              partnersData.fields.partners,
            )}
          />
        </SpacedSection>

        <CtasSection
          title={landingPageData.fields.grimme_group.title}
          description={landingPageData.fields.grimme_group.description}
          backgroundImageUrl={
            landingPageData.fields.grimme_group.background_image
          }
          buttons={parseCtaSectionButtons(
            (landingPageData.fields.grimme_group &&
              landingPageData.fields.grimme_group.buttons) ||
              [],
          )}
        />

        <SpacedSection px="large">
          <ContactSection {...landingPageData.fields.contact_section} />
        </SpacedSection>
      </Layout>
    </GrimmeDataPerRegionProvider>
  );
}
