import { Button } from '@grimme/components';
import theme from '@grimme/theme';
import { Box, Card, Typography } from '@mui/material';
import Link from 'next/link';
import { classes, StyledBox } from './styles';
import { SingleMarkerMap } from '@/ui/single-marker-map/single-marker-map';
import { useContactsPerRegion } from '@/ui/grimme-data-per-region/use-contacts-per-region';

export type PartnerCardProps = {
  partnerButton: string;
};

export const PartnerCard = (props: PartnerCardProps) => {
  const { partnerButton } = props;
  const contactsPerRegion = useContactsPerRegion();
  const localRepresentation = contactsPerRegion?.local_representation;

  return (
    <StyledBox className={classes.root}>
      <SingleMarkerMap
        address={localRepresentation?.address}
        hasOverlayingCard
      />
      <Card className={classes.card}>
        <Typography className="g-typography-h5">
          {localRepresentation?.name}
        </Typography>
        <Box py={theme.spacing(2)}>
          <Typography variant="body1">
            {localRepresentation?.address}
          </Typography>
          <Typography
            className={classes.link}
            component="a"
            href={`tel:${localRepresentation?.phone}`}
            variant="body1"
          >
            {localRepresentation?.phone}
          </Typography>
          <Typography
            className={classes.link}
            component="a"
            href={`mailto:${localRepresentation?.email}`}
            variant="textLink"
          >
            {localRepresentation?.email}
          </Typography>
        </Box>
        <Link passHref href="/partners">
          <Button className={classes.button} variant="secondary">
            {partnerButton}
          </Button>
        </Link>
      </Card>
    </StyledBox>
  );
};
